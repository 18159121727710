export const colors = {
  black: '#000000',
  godGray: '#171617',
  ebonyClay: '#29323c',
  riverBed: '#485563',
  gray: '#888888', 
  porcelain: '#ECEFF1',
  softPeach: '#FDFBFB',
  alabaster: '#F8F8F8',
  white: '#ffffff',
  white_15: 'rgba(255,255,255,0.15)',
  cinnabar: '#E53935',
  maroon: '#940019',
  fiord_01: 'rgba(74, 83, 105, 0.1)',
  black_01: 'rgba(0, 0, 0, 0.1)',
  transparent: 'transparent'
}

const fonts = {
  openSans: "'Open Sans', sans-serif",
  roboto: "'Roboto', sans-serif",
  montserrat: "'Montserrat', 'Trebuchet MS', Helvetica, sans-serif",
  raleway: "'Raleway', sans-serif"
};

export default {
  colors,
  fonts
};

import styled from 'styled-components';
import CheckIcon from '../../graphics/checkIcon';
import House from '../../graphics/house';
import Building from '../../graphics/building';
import BuildingSite from '../../graphics/buildingSite';
import Engineer from '../../graphics/engineer';
import Manager from '../../graphics/manager';
import Idea from '../../graphics/idea';
import { breakpoint } from '../../styles/breakpoints';


export const Option = styled.span`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  margin: 8px 5px;

  color: ${({ theme }) => theme.colors.alabaster};
  font-weight: 500;
  font-size: 1rem;
  text-align: left;

  p {
    width: 95%;
    padding-left: 10px;
  }  
`;

export const StyledCheckIcon = styled(CheckIcon)`
  width: 16px;
  height: 16px;  
  margin-top: 5px;

  fill: ${({ theme }) => theme.colors.softPeach};
`;

export const Offer = styled.div`    
  display: flex;
  flex-direction: column;
  width: 100%;   
  height: 100%;

  ${breakpoint.pm`
    width: 48%;
    max-width: 450px;
    margin-bottom: 30px;
  `}

  ${breakpoint.xl`
    width: 33%;
    margin-bottom: 50px;
  `}

  ${breakpoint.xxl`
    width: 30%;
  `}
`;

export const StyledHouse = styled(House)`
  width: 80px;
  height: 80px;

  fill: #e52d27;
`;

export const StyledBuilding = styled(Building)`
  width: 80px;
  height: 80px;

  fill: #e52d27;
`;

export const StyledBuildingSite = styled(BuildingSite)`
  width: 80px;
  height: 80px;

  fill: #e52d27;
`;

export const StyledEngineer = styled(Engineer)`
  width: 80px;
  height: 80px;

  fill: #e52d27;
`;

export const StyledManager = styled(Manager)`
  width: 80px;
  height: 80px;

  fill: #e52d27;
`;

export const StyledIdea = styled(Idea)`
  width: 80px;
  height: 80px;

  fill: #e52d27;
`;

export const Top = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 95%;
  height: 64px;
  margin: 0 auto;
  padding: 12px 0;
  
  border-radius: 20px;  

  background-image: linear-gradient(to right, #e52d27, #b31217);

  ${breakpoint.pm`
    order: 1;
    margin: 20px auto 40px auto;
  `}
`;

export const Title = styled.h3`
  width: 100%;
  margin: 0 auto;  
  padding: 0 10px;

  color: ${({ theme }) => theme.colors.white};
  font-weight: 600;
  font-size: 1.1rem;
  font-family: ${({ theme }) => theme.fonts.raleway};
  text-align: center;  
  text-transform: uppercase;

  ${breakpoint.pm`
    padding: 0 20px;
  `}
`;

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  width: 95%;
  margin: 15px auto;

  ${breakpoint.pm`
    order: 0;
  `}
`;

export const Image = styled.div`  
  display: flex;
  align-items: center;
  justify-content: center;
  height: 130px;
  
  border-top-left-radius: 40px;

  background-color: ${({ theme }) => theme.colors.white};
`;

export const Text = styled.div`
  box-sizing: border-box;
  height: 230px;
  padding: 10px 20px 15px 20px;
  border-bottom-right-radius: 40px;

  background-image: linear-gradient(to right, #e52d27, #b31217);

  ${breakpoint.xm`
    padding: 15px 40px;
  `}
`;

export const Wrapper = styled.div`
  width: 95%;
  margin: 0 auto;
  margin-bottom: 10px;
  border-radius: 20px;  

  background-color: ${({ theme }) => theme.colors.white};

  ${breakpoint.pm`
    order: 2;   
  `}
`;

export const Information = styled.p`
  display: flex;
  align-items: center;
  box-sizing: border-box;
  height: 140px;
  margin: 0 auto;
  padding: 15px;

  color: ${({ theme }) => theme.colors.godGray};
  font-weight: 400;
  font-size: 0.92rem;
  text-align: center;

  ${breakpoint.pm`
    height: 160px; 
    padding: 25px;  
  `}

  ${breakpoint.xm`
    font-size: 1rem;  
  `}
`;

export const Intro = styled.p`  
  padding-left: 0 !important;
`;
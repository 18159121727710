import React from 'react';
import { useTranslation } from 'react-i18next';
import { useStaticQuery, graphql } from 'gatsby';
import { 
  Image,
  Title,
  SubTitle,
  TitleWrapper
} from './styled-components';

const Intro = () => {
  const { t } = useTranslation();
  const data = useStaticQuery(graphql`
    query {
      buildingImage: file(relativePath: { eq: "building2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1900, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <div>
      <Image     
        imgStyle={{ objectFit: 'cover' }}   
        fluid={data.buildingImage.childImageSharp.fluid}
        alt={t('intro.image')}
      />
      <TitleWrapper>
        <Title>{t('intro.architect')}</Title>
        <SubTitle>{t('intro.full_name')}</SubTitle>
      </TitleWrapper>
    </div>
  )
};

export default Intro;

import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import { useTranslation } from 'react-i18next';
import paths from '../utils/paths';

const SEO = ({
  xmlns,
  lang,
  title,
  description,
  keywords,
  ogImage,
  ogPath
}) => {
  const { t } = useTranslation();
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteUrl
          }
        }
      }
    `
  )

  const pageTitle = t('page_title');
  const metaTitle = title || pageTitle;
  const metaDescription = description || t('page_description');
  const metaKeywords = keywords || t('page_keywords');
  const titleTemplate = title ? `%s * ${pageTitle}` : '%s';
  const metaOgSiteName = t('page_name');
  const metaOgImage = ogImage;
  const metaOgPath = ogPath;
  const siteUrl = data.site.siteMetadata.siteUrl;

  return (
    <Helmet
      htmlAttributes={{
        xmlns,
        lang,
        'xml:lang': lang
      }}
      title={metaTitle}
      titleTemplate={titleTemplate}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: 'keywords',
          content: metaKeywords
        },
        {
          name: 'viewport',
          content: 'width=360, initial-scale=1'
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: 'og:site_name',
          content: metaOgSiteName
        },
        {
          property: `og:title`,
          content: metaTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: 'og:image',
          content: siteUrl + metaOgImage
        },
        {
          property: 'og:url',
          content: siteUrl + metaOgPath
        }
      ]}
    />
  )
}

SEO.defaultProps = {
  xmlns: paths.xmlns,
  lang: `pl-PL`,
  title: '',
  description: '',
  keywords: '',
  ogImage: '',
  ogPath: ''
}

SEO.propTypes = {
  xmlns: PropTypes.string,
  lang: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  keywords: PropTypes.string,
  ogImage: PropTypes.string,
  ogPath: PropTypes.string
}

export default SEO;

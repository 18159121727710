import React from 'react';
import Slider from 'react-slick';
import styled from 'styled-components';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { breakpoint } from '../../styles/breakpoints';

const settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  centerPadding: '10%'
};

const SimpleSlider = ({ children, className }) => (
  <div className={className}>
    <Slider {...settings}>{children}</Slider>
    <DesktopContentWrapper>{children}</DesktopContentWrapper>
  </div>
);

export const DesktopContentWrapper = styled.div`
  display: none;
  width: 100%;

  margin: 0 auto; 

  ${breakpoint.pm`  
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;    
    justify-content: space-around;
  `}
`;

export default styled(SimpleSlider)`
  width: 100%;
  margin: 0 auto;  

  .slick-slide {
    height: auto;

    > div {
      box-sizing: border-box;
      height: 100%;
      margin: 0 10px;
    }
  }

  .slick-dots {
    position: static;
  }

  .slick-dots > li {
    margin: 0;
  }

  .slick-dots > li > button:before {
    color: ${({ theme }) => theme.colors.ebonyClay};
    font-size: 13px;

    opacity: 0.2;
  }

  .slick-dots > .slick-active > button:before,
  .slick-dots > li > button:hover:before {
    color: ${({ theme }) => theme.colors.ebonyClay};

    opacity: 1;
  }

  ${breakpoint.pm`
    .slick-slider {
      display: none;
    }
  `}
`;

import React from 'react';

export default props => {
  return (
    <svg 
      xmlns="http://www.w3.org/2000/svg" 
      viewBox="0 0 64 64" 
      width="512" 
      height="512"
      {...props}
    >
      <path d="m61 45.653v-30.894l-13 2.826v-6.585h-12v-8h-2v8h-2v-4h-2v4h-2v-6h-2v6h-4v-4h-2v-4h-2v4h-2v-6h-2v6h-2v-2h-2v2h-2v4h-4v16.531l-3-.857v36.326h62v-13.764c0-1.513-.804-2.833-2-3.583zm-2-.629c-.08-.005-.155-.024-.236-.024h-1.764v-25.237l-42 9v-1.957l44-9.565zm-48.936-7.635-5.549-7.63 9.71 2.774zm12.936-6.389v6h-6v-5.754l-.86-.246zm2 0h6v6h-6zm8 0h6v6h-6zm8 0h6v6h-6zm8 0h6v6h-6zm6-2h-6v-5.477l6-1.286zm-8 0h-6v-3.763l6-1.286zm-8 0h-6v-2.049l6-1.286zm-8 0h-7.56l7.56-1.62zm-14 10h6v6h-6zm8 0h6v6h-6zm8 6v-6h6v6h-5.764c-.081 0-.156.019-.236.024zm8-6h6v6h-6zm8 0h6v6h-6zm-39-30h10v2h-10zm36 4v2h-40v-2zm-40 4h40v1.02l-33 7.174v4.909l-7-2zm-3 14.075 6 8.25v21.675h-6zm8 29.925v-21.63l4-4.666v26.296zm14 0h-4v-10h4zm2 0v-12h-8v12h-2v-14h12.653c-.407.651-.653 1.413-.653 2.236 0 .654.155 1.31.447 1.895l4.935 9.869zm30 0h-4v-10h4zm-6 0h-4v-10h4zm-6 0h-4v-10h4zm16 0h-2v-12h-20v12h-2.382l-5.382-10.764c-.155-.309-.236-.655-.236-1 0-1.233 1.003-2.236 2.236-2.236h25.528c1.233 0 2.236 1.003 2.236 2.236z" />
    </svg>
  );
};
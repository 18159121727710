import '../locales/config-i18next';

import React from "react";
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import SEO from './seo';
import GlobalStyles from '../styles/global-styles';

import theme from '../styles/theme';
import Footer from './footer/footer';
import Navigation from './navigation/navigation';
import Intro from './intro/intro';
import Offers from './offers/offers';

const Layout = ({ 
  children,
  className,
  pageTitle,
  pageDescription,
  pageKeywords,
  ogImage,
  ogPath
}) => (
    <ThemeProvider theme={theme}>
      <div className={className}>        
        <GlobalStyles />
        <SEO
          title={pageTitle}
          description={pageDescription}
          keywords={pageKeywords}
          ogImage={ogImage}
          ogPath={ogPath}
        />
        <Navigation />
        <Intro />
        <Offers />
        {children}
        <Footer />
      </div>
    </ThemeProvider>
  )


Layout.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  pageTitle: PropTypes.string,
  pageDescription: PropTypes.string,
  pageKeywords: PropTypes.string,
  ogImage: PropTypes.string,
  ogPath: PropTypes.string
}

Layout.defaultProps = {
  className: '',  
  pageTitle: '',
  pageDescription: '',
  pageKeywords: '',
  ogImage: '',
  ogPath: ''
};

export default Layout;
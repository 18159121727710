import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';
import { breakpoint } from '../../styles/breakpoints';
import { checkWindowDefined, getScrollValue } from '../../utils/functions';
import Menu from './menu-content';
import Logo from '../../graphics/logo';

class Navigation extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      visible: true,
      lastScrollOffset: null,
      isMobileMenuOpen: false,
    };

    this.userMenuContainer = React.createRef();
  }

  componentDidMount() {
    checkWindowDefined() &&
      window.addEventListener('scroll', this.handleScroll);
    checkWindowDefined() && window.addEventListener('load', this.handleScroll);
  }

  componentWillUnmount() {
    checkWindowDefined() &&
      window.removeEventListener('scroll', this.handleScroll);
    checkWindowDefined() &&
      window.removeEventListener('load', this.handleScroll);
  }

  render() {
    const {
      t,
    } = this.props;

    const {
      visible,
      lastScrollOffset,
      isMobileMenuOpen,
    } = this.state;

    return (
      <NavbarWrapper
        visible={visible}
        ref={this.userMenuContainer}
        isMenuOpen={isMobileMenuOpen}
        wasNotScrolled={lastScrollOffset === null}
      >
        <NavbarContent>     
          <Wrapper>
          <StyledLogoColor isOpen={isMobileMenuOpen} />
          <Menu 
            isMobileMenuOpen={isMobileMenuOpen}
            closeMenu={this.closeMenu}
            t={t}
          />   
          <Hamburger
            isOpen={isMobileMenuOpen}
            onClick={this.handleHamburgerClick}
          />
          </Wrapper>
        </NavbarContent>
      </NavbarWrapper>
    );
  }  

  handleScroll = () => {
    const isMenuOpen = this.state.isMobileMenuOpen;
    const currentScrollOffset = getScrollValue();
    const isScrollingUpwards =
      currentScrollOffset < this.state.lastScrollOffset;
    const isScrolledToTop = currentScrollOffset === 0;
    const isMenuVisible = isMenuOpen || isScrollingUpwards || isScrolledToTop;

    this.setState({
      visible: isMenuVisible,
      lastScrollOffset: currentScrollOffset
    });
  };

  handleHamburgerClick = () => {
    const isOpen = this.state.isMobileMenuOpen;
    this.setState({ isMobileMenuOpen: !isOpen });
  };

  closeMenu = () => this.setState({ isMobileMenuOpen: false });

}

export default withTranslation()(Navigation);


const NavbarWrapper = styled.nav`
  position: ${({ wasNotScrolled }) => (wasNotScrolled ? 'absolute' : 'fixed')};
  top: 0;
  z-index: 2000;

  box-sizing: border-box;
  width: 100%;
  height: 56px;

  text-align: center;

  background-color: ${({ theme, isMenuOpen}) =>  isMenuOpen ? theme.colors.white : 'inherit'};
  background-image: ${({ theme, isMenuOpen }) =>  isMenuOpen ?  'inherit' : `linear-gradient(to right, ${theme.colors.ebonyClay} 0%, ${theme.colors.riverBed} 100%)`};

  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
  opacity: ${({ visible }) => (visible ? '1' : '0')};

  transition: ${({ isMenuOpen }) =>
    !isMenuOpen
      ? 'opacity 0.3s, visibility 0.3s linear, background-color 0.2s linear 0.5s'
      : 'opacity 0.3s, visibility 0.3s linear'};
  
  ${breakpoint.l`
    height: 80px;
    transition: opacity 0.3s, visibility 0.3s linear, background-color 0.3s linear;
  `}
`;

export const NavbarContent = styled.div`
  position: relative;

  display: inline-block;

  box-sizing: border-box;
  width: 100%;
  max-width: 1326px;
  height: 100%;
  margin: auto;
  padding: 8px 20px;

  text-align: center;

  ${breakpoint.l`
    padding: 0 40px;
  `}
`;

const HamburgerLine = styled.span`
  position: absolute;
  left: 7px;

  display: block;
  width: 24px;
  height: 2px;

  background: ${({ theme, isOpen }) => isOpen ? theme.colors.godGray : theme.colors.white};

  transition: ${({ isOpen }) =>
    isOpen
      ? '0.25s ease-in-out'
      : '0.25s ease-in-out, background 0s linear 0.5s'};

`;

const FirstHamburgerLine = styled(HamburgerLine)`
  top: ${({ isOpen }) => (isOpen ? '19px' : '12px')};
  left: ${({ isOpen }) => (isOpen ? '50%' : '7px')};

  width: ${({ isOpen }) => (isOpen ? '0' : '24px')};
`;

const SecondHamburgerLine = styled(HamburgerLine)`
  top: 19px;

  transform: ${({ isOpen }) => (isOpen ? 'rotate(45deg)' : 'none')};
`;

const ThirdHamburgerLine = styled(HamburgerLine)`
  top: 19px;

  transform: ${({ isOpen }) => (isOpen ? 'rotate(-45deg)' : 'none')};
`;

const FourthHamburgerLine = styled(HamburgerLine)`
  top: ${({ isOpen }) => (isOpen ? '19px' : '26px')};
  left: ${({ isOpen }) => (isOpen ? '50%' : '7px')};

  width: ${({ isOpen }) => (isOpen ? '0' : '24px')};
`;

const HamburgerIcon = ({
  className,
  onClick,
  isOpen,
}) => (
  <div className={className} onClick={onClick}>
    <FirstHamburgerLine
      isOpen={isOpen}
    />
    <SecondHamburgerLine
      isOpen={isOpen}
    />
    <ThirdHamburgerLine
      isOpen={isOpen}
    />
    <FourthHamburgerLine
      isOpen={isOpen}
    />
  </div>
);

export const Hamburger = styled(HamburgerIcon)`
  position: absolute;
  top: 8px;
  right: 10px;

  width: 40px;
  height: 40px;

  cursor: pointer;

  ${breakpoint.l`
    display: none;
  `}
`;

const StyledLogoColor = styled(({ isOpen, ...props }) => <Logo {...props} />)`
  width: auto;
  height: 30px;   

  color: ${({ theme, isOpen }) => isOpen ? theme.colors.black : theme.colors.white};

  ${breakpoint.l`
    height: 50px; 
  `}
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
`;
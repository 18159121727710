
import { checkWindowDefined } from './functions';

export const isInnerHashLink = url => {
  if (!checkWindowDefined() || url.indexOf('/#') == -1) return false;

  const urlWithoutHash = url.substr(0, url.indexOf('#'));

  return window.location.pathname == urlWithoutHash;
};

export default {
  home: '/',

  home_company: '/#firma',
  home_offer: '/#oferta',
  home_contact: '/#kontakt',

  facebook: 'https://www.facebook.com/ALEX-Architekt-109464630789885',
  xmlns: 'http://www.w3.org/1999/xhtml',
}
import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Carousel from './carousel';
import { breakpoint } from '../../styles/breakpoints';

import Houses from './houses';
import Buildings from './buildings';
import Modernization from './modernization';
import Inventories from './inventories';
import AdditionalOption from './additional-option';
import Supervision from './supervision';

const Offers = ({ className }) => {
  const { t } = useTranslation();

  return (
    <div id='oferta' className={className}>     
      <SectionTitle dangerouslySetInnerHTML={{
          __html: t('offers.title')
        }}
      /> 
      <SubTitle dangerouslySetInnerHTML={{
          __html: t('offers.description')
        }}
      /> 
      <Wrapper>
        <Carousel>
          <Houses />
          <Buildings />
          <Modernization />
          <Inventories />
          <Supervision />
          <AdditionalOption />
        </Carousel>
      </Wrapper>
      <Contact dangerouslySetInnerHTML={{
          __html: t('offers.contact')
        }}
      /> 
    </div>
  )
};


export default styled(Offers)`
  box-sizing: border-box;    
  padding: 20px 10px;  

  background-color: ${({ theme }) => theme.colors.porcelain};

  ${breakpoint.pm`    
    padding: 50px 0;
    width: 100%;    
  `}
`;

const Wrapper = styled.div`
  max-width: 420px;
  height: 100vh;
  margin: 0 auto;

  font-family: ${({ theme }) => theme.fonts.openSans};

  ${breakpoint.s`
    height: 100%;
  `}

  ${breakpoint.pm`
    width: 90%;
    margin: 0 auto;
    max-width: 1326px;
  `}
`;

const SectionTitle = styled.h1`
  width: calc(90% + 20px);
  margin: 0 auto 30px auto;
  padding-bottom: 10px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.cinnabar};

  color: ${({ theme }) => theme.colors.godGray};
  font-weight: 800;
  font-size: 1.2rem;
  font-family: ${({ theme }) => theme.fonts.raleway};
  letter-spacing: 1px;
  text-align: left;  
  text-transform: uppercase;

  ${breakpoint.pm`
    width: 90%;
    margin: 0 auto;
    font-size: 1.3rem;
    max-width: 1326px;
  `}
`;

const SubTitle = styled.p`
  margin-bottom: 30px;
  padding: 0 15px;

  color: ${({ theme }) => theme.colors.godGray};
  font-weight: 400;
  font-size: 1rem;
  font-family: ${({ theme }) => theme.fonts.openSans};  
  text-align: center;

  ${breakpoint.pm`
    font-size: 1.2rem;
    margin: 40px auto;
    max-width: 1326px;
  `}
`;

const Contact = styled(SubTitle)`
  margin-bottom: 10px;
  ${breakpoint.s`
    margin-top: 30px;
  `}
`;

export const Text = styled.p`
  margin: 5px 10px;

  color: ${({ theme }) => theme.colors.godGray};
  font-weight: 400;
  font-size: 1rem;
  text-align: left;

  :hover {
    cursor: pointer;
  }
`;
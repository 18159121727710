import React, { Fragment } from 'react';
import styled, { css } from 'styled-components';
import Link from '../link';
import paths from '../../utils/paths';
import { breakpoint } from '../../styles/breakpoints';

const Menu = ({
  t,
  isMobileMenuOpen,
  closeMenu,
}) => (
  <Fragment>
    <MenuContent isOpen={isMobileMenuOpen}>
      <LinksWrapper>
        <NavLink
          to={paths.home} 
          aria-label={t('links_labels.home')}
          title={t('links_labels.home')}
          onClick={closeMenu}
        >
          {t('navigation.home')}
        </NavLink>        
        <NavLink
          to={paths.home_offer} 
          aria-label={t('links_labels.offer')}
          title={t('links_labels.offer')}
          onClick={closeMenu}
        >
          {t('navigation.offer')}
        </NavLink>
        <NavLink
          to={paths.home_company} 
          aria-label={t('links_labels.company')}
          title={t('links_labels.company')}
          onClick={closeMenu}
        >
          {t('navigation.company')}
        </NavLink>
        <NavLink
          to={paths.home_contact} 
          aria-label={t('links_labels.contact')}
          title={t('links_labels.contact')}
          onClick={closeMenu}
        >
          {t('navigation.contact')}
        </NavLink>
      </LinksWrapper>
    </MenuContent>
  </Fragment>
);

export default Menu;

const MenuContent = styled.div`
  position: fixed;
  top: 52px;
  left: 0;

  display: block;
  box-sizing: border-box;
  width: 100%;
  height: calc(100% - 30px);
  padding-top: 18px;

  overflow-y: auto;

  background-color: ${({ theme }) => theme.colors.white}; 

  transform: ${({ isOpen }) => (isOpen ? 'scaleY(1)' : 'scaleY(0)')};
  transform-origin: top;

  transition: transform 0.3s linear 0.2s;

  ::-webkit-scrollbar {
    display: none;
  }

  > div {
    opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};

    transition: ${({ isOpen }) =>
      isOpen ? 'opacity 0.2s ease-in 0.4s' : 'opacity 0.2s ease-in'}; 

    ${breakpoint.l`      
      opacity: 1;
    `}
  }

  ${breakpoint.l`
    position: static;
    display: inline-flex;
    justify-content: flex-end;

    width: 100%;
    height: 80px;
    padding: 21px 0 49px 0;

    background-color: transparent;
    opacity: 1;

    transform: scaleY(1);
  `}
`;

const LinksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  margin: 0 auto;

  color: ${({ theme }) => theme.colors.godGray};
  font-weight: 600;
  text-align: left;

  ${breakpoint.l`
    width: 100%;
    flex-direction: row;
    justify-content: flex-end;
  `}
`;

const linkStyles = css`
  display: block;
  padding: 20px 0 10px 0;

  color: ${({ theme }) => theme.colors.godGray};
  font-weight: 500;
  font-size: 1.3rem;
  font-family: ${({ theme }) => theme.fonts.raleway};
  
  text-align: left;
  text-transform: uppercase;
  text-decoration: none;

  cursor: pointer;  
  
  ::after {
    display: block;

    width: 0;
    height: 2px;

    background: ${({ theme }) => theme.colors.cinnabar};

    transition: width 0.5s;
    
    content: '';
  }
  :hover::after{
    width: 100%;
  }

  ${breakpoint.l`  
    width: 100px;
    height: 25px;
    margin: 0 10px;
    font-size: 1.3rem;
    color: ${({ theme }) => theme.colors.white};
    padding: 10px 0 0 0;
    text-align: center;

    :hover {
      color: ${({ theme }) => theme.colors.white};
    }
  `}
`;

export const NavLink = styled(Link)`
  ${linkStyles};
`;
import React from 'react';
import { useTranslation } from 'react-i18next';
import { 
  Top,
  Box,
  Text,
  Offer,
  Title,
  Image,
  Option,
  Wrapper,
  StyledManager,
  Information,
  StyledCheckIcon,
} from './styled-components';

const Supervision= ({ className }) => {
  const { t } = useTranslation();

  return (
    <Offer className={className}>
      <Top>
        <Title dangerouslySetInnerHTML={{
            __html: t('offers.construction_supervision.title')
          }}
        />
      </Top>
      <Box>
        <Image>
          <StyledManager />
        </Image>
        <Text>
          <Option>
            <StyledCheckIcon />
            <p dangerouslySetInnerHTML={{
                __html: t('offers.construction_supervision.option_1')
              }}
            />
          </Option>              
          <Option>
            <StyledCheckIcon />
            <p dangerouslySetInnerHTML={{
                __html: t('offers.construction_supervision.option_2')
              }}
            />
          </Option>            
        </Text>
      </Box>
      <Wrapper>
        <Information dangerouslySetInnerHTML={{
            __html: t('offers.construction_supervision.information')
          }}
        />
      </Wrapper>
    </Offer>

  )
}

export default Supervision;
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { CONTACT } from '../../utils/consts';
import { breakpoint } from '../../styles/breakpoints';
import paths from '../../utils/paths';
import Link from '../link';

import { 
  Nip,
  Text,
  Follow,
  Header,
  PinIcon,
  Section,
  Contact,
  Wrapper,
  TextWrapper,
  PhoneIcon,
  EmailIcon,
  SubSection,
  AboutUs,
  Information,
  IconWrapper,
  FollowHeader,
  SectionHeader,
  HeaderWrapper,
  PhoneHeaderWrapper,
  CopyRightLink,
  StyledLogoColor,
  CopyRightSection,
  StyledFacebookIcon,
  StyledInstagramIcon,
} from './styled-components';

const Footer = ({ className }) => {
  const { t } = useTranslation();

  return (
    <footer id='kontakt' className={className}>
      <Section title={t('footer.contact')}>    
        <SubSection>          
          <AboutUs>
            <StyledLogoColor />
            <TextWrapper>
              <SectionHeader>{t('footer.company')}</SectionHeader>
              <Information dangerouslySetInnerHTML={{__html: t('footer.about_us')}}/>
            </TextWrapper>
          </AboutUs>
          <Contact>
            <SectionHeader>{t('footer.contact')}</SectionHeader>
            <Wrapper>
              <div>
                <Text>{CONTACT.full_name}</Text>       
                <Nip dangerouslySetInnerHTML={{
                  __html: t('footer.nip', {
                    nipNumber: CONTACT.nip
                  })
                }} />
                <HeaderWrapper>
                  <PinIcon />
                  <Header>{t('footer.address')}</Header>
                </HeaderWrapper>
                <Text>{CONTACT.street}</Text>
                <Text>{CONTACT.address}</Text>
              </div>
              <div>
                <PhoneHeaderWrapper>
                  <PhoneIcon />
                  <Header>{t('footer.phone')}</Header>
                </PhoneHeaderWrapper>
                <Text><a href={`tel:${CONTACT.phone_number.split(' ').join('-')}`}>{CONTACT.phone_number}</a></Text>
                <HeaderWrapper>
                  <EmailIcon />
                  <Header>{t('footer.email')}</Header>
                </HeaderWrapper>
                <Text><a href={`mailto:${CONTACT.email}`}>{CONTACT.email}</a></Text>            
              </div>
            </Wrapper>
          </Contact>
          <Follow>
            <FollowHeader>{t('footer.follow_us')}</FollowHeader>
            <IconWrapper>
              <Link
                to={paths.facebook}
                aria-label={t('links_labels.facebook_button')}
                title={t('links_titles.facebook_button')}
                target="_blank"
              >
                <StyledFacebookIcon />
              </Link>
              <StyledInstagramIcon />
            </IconWrapper>
          </Follow>
        </SubSection>  
        <CopyRightSection>
          <CopyRightLink>&copy; {t('footer.copyright')}</CopyRightLink>
          <CopyRightLink>{t('footer.designer')}</CopyRightLink>
        </CopyRightSection>
      </Section>
    </footer>
  )
}

export default styled(Footer)`
  display: block;
  width: 100%;
  height: 100vh;

  background-image: ${({ theme }) => `linear-gradient(60deg, ${theme.colors.ebonyClay} 0%, ${theme.colors.riverBed} 100%)`};

  ${breakpoint.l`    
    height: 100%;
    display: flex;    
    align-items: center;    
  `}
`;
import React from 'react';

import { useTranslation } from 'react-i18next';
import { 
  Top,
  Box,
  Text,
  Offer,
  Title,
  Image,
  Intro,
  Option,
  Wrapper,
  StyledBuildingSite,
  Information,
  StyledCheckIcon,
} from './styled-components';


const Modernization = ({ className }) => {
  const { t } = useTranslation();

  return (
    <Offer className={className}>
      <Top>
        <Title dangerouslySetInnerHTML={{
            __html: t('offers.modernization_and_reconstruction.title')
          }}
        />
      </Top>
      <Box>
        <Image>
          <StyledBuildingSite />
        </Image>
        <Text>
          <Option>
            <Intro dangerouslySetInnerHTML={{
                __html: t('offers.modernization_and_reconstruction.subtitle')
              }}
            />
          </Option>              
          <Option>
            <StyledCheckIcon />
            <p dangerouslySetInnerHTML={{
                __html: t('offers.modernization_and_reconstruction.option_1')
              }}
            />
          </Option>
          <Option>
            <StyledCheckIcon />
            <p dangerouslySetInnerHTML={{
                __html: t('offers.modernization_and_reconstruction.option_2')
              }}
            />
          </Option>
          <Option>
            <StyledCheckIcon />
            <p dangerouslySetInnerHTML={{
                __html: t('offers.modernization_and_reconstruction.option_3')
              }}
            />
          </Option>
          <Option>
            <StyledCheckIcon />
            <p dangerouslySetInnerHTML={{
                __html: t('offers.modernization_and_reconstruction.option_4')
              }}
            />
          </Option>
        </Text>
      </Box>
      <Wrapper>
        <Information dangerouslySetInnerHTML={{
            __html: t('offers.modernization_and_reconstruction.information')
          }}
        />
      </Wrapper>
    </Offer>

  )
}

export default Modernization;
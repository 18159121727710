import styled from 'styled-components';
import FacebookIcon from '../../graphics/facebookIcon';
import InstagramIcon from '../../graphics/instagramIcon';
import Pin from '../../graphics/pin';
import Phone from '../../graphics/phone';
import Email from '../../graphics/email';
import Logo from '../../graphics/logo';
import { breakpoint } from '../../styles/breakpoints';

export const AboutUs = styled.div`
  display: none;
  ${breakpoint.l`
    width: 45%;    
    height: 210px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
  `}
  ${breakpoint.xl`
    width: 50%;   
  `}
`;

export const Information = styled.p`
  width: 75%;
  margin: 5px 0;

  color: ${({ theme }) => theme.colors.white};
  font-weight: 300;
  font-size: 1rem;
  text-align: left;  
`;

export const Contact = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;  
  width: 100%;
  height: 75vh;  

  ${breakpoint.l`
    height: 210px;
    width: 40%;
    justify-content: space-between;
  `}
`;

export const TextWrapper = styled.div`
  width: 100%;

  h3 {
    padding-bottom: 10px;
  }
`;

export const Follow = styled.div`
  ${breakpoint.l`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 210px;
    width: 20%;
  `}
`;

export const Wrapper = styled.div`
  width: 90%;
  margin: 0 auto;  

  ${breakpoint.l`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;

    div {
      width: 50%;
      margin-right: 20px;
    }
  `}
`;

export const Section = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;  

  ${breakpoint.l`  
    align-items: center;    
    justify-content: center;
  `}
`;

export const SubSection = styled.div`
  ${breakpoint.l`  
    width: 100%;
    height: 300px;
    display: flex;
    flex-direction: row;
    align-items: center;    
    justify-content: center;
    max-width: 1326px; 
    padding: 0 40px;
    box-sizing: border-box;
  `}
`;

export const IconWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 90%;
  height: 15vh;
  margin: 0 auto;
  border-top: 1px solid ${({ theme }) => theme.colors.white_15};

  ${breakpoint.l`
    border-top: none;
    width: 100%;
    margin: 0;
    height: 160px;
    align-items: flex-start;
  `}
`;

export const SectionHeader = styled.h3`
  width: 90%;
  margin: 0 auto 30px auto;
  padding-bottom: 10px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.white_15};

  color: ${({ theme }) => theme.colors.alabaster};
  font-weight: 500;
  font-size: 1.3rem;
  font-family: ${({ theme }) => theme.fonts.raleway};
  text-align: left;  
  text-transform: uppercase;

  ${breakpoint.l`
    border-bottom: none;
    width: 100%;
    margin: 0;
    padding-bottom: 25px;
  `}
`;

export const FollowHeader = styled(SectionHeader)`
  display: none;
  ${breakpoint.l`
    display: block;
    text-align: center;
  `}
`;

export const Text = styled.p`
  margin: 5px 0;

  color: ${({ theme }) => theme.colors.white};
  font-weight: 300;
  font-size: 1rem;
  text-align: left;  

  a {
    color: ${({ theme }) => theme.colors.white};

    cursor: pointer;

    :hover {
      color: ${({ theme }) => theme.colors.white};           
    }
  }
`;

export const Nip = styled(Text)`
  margin-top: 10px;

  color: ${({ theme }) => theme.colors.white};
  
  pointer-events: none; 
`;

export const StyledFacebookIcon = styled(FacebookIcon)`
  width: 35px;
  height: 35px;
  margin: 0 20px;

  fill: ${({ theme }) => theme.colors.alabaster};
`;

export const StyledInstagramIcon = styled(InstagramIcon)`
  width: 35px;
  height: 35px;
  margin: 0 20px;

  fill: ${({ theme }) => theme.colors.alabaster};
`;

export const PinIcon = styled(Pin)`
  width: 18px;
  height: 18px;
  margin-right: 10px;

  fill: ${({ theme }) => theme.colors.alabaster};
`;

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 30px 0 10px 0;
`;

export const PhoneHeaderWrapper = styled(HeaderWrapper)`
  ${breakpoint.l`    
    margin: 4px 0 12px 0;
  `}
`;

export const Header = styled.h4`
  color: ${({ theme }) => theme.colors.alabaster};
  font-weight: 400;
  font-size: 1rem;
  text-align: left; 
`;

export const PhoneIcon = styled(Phone)`
  width: 18px;
  height: 18px;
  margin-right: 5px;

  fill: ${({ theme }) => theme.colors.alabaster};
`;

export const EmailIcon = styled(Email)`
  width: 18px;
  height: 18px;
  margin-right: 10px;
`;

export const CopyRightSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 10vh;

  background-image: ${({ theme }) => `linear-gradient(120deg, ${theme.colors.softPeach} 0%,${theme.colors.porcelain} 100%)`};
  ${breakpoint.l`    
    height: 90px;
  `}
`;

export const CopyRightLink = styled.p`  
  margin: 3px 0;
  
  color: ${({ theme }) => theme.colors.gray};
  font-size: 0.8rem;
`;

export const StyledLogoColor = styled(Logo)`
  width: auto;
  height: 40px;   

  color: ${({ theme }) => theme.colors.white};  

  ${breakpoint.xl`    
    height: 50px;
  `}
`;
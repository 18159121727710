export const checkWindowDefined = () => typeof window !== 'undefined';

export const getScrollValue = () =>
  checkWindowDefined()
    ? typeof window.pageYOffset != 'undefined'
      ? window.pageYOffset
      : document.documentElement.scrollTop
      ? document.documentElement.scrollTop
      : document.body.scrollTop
      ? document.body.scrollTop
      : 0
    : 0;
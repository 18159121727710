export default {
  pl: {
    translation: {
      page_name: 'ALEX-Architekt - biuro projektowe',
      page_title: 'ALEX-Architekt • Usługi projektowe  ',
      page_description: 'Kompleksowa obsługa projektów wraz z wszystkimi formalnościami w urzędach.',
      page_keywords: 'architekt, Architekt Choszczno, architektura, Choszczno, biuro projektowe, Szczecin, projekty, adaptacje, usługi projektowe, alex architekt, Aleksandra Żołnierzów',
      navigation: {
        home: 'Home',
        company: 'Firma',
        offer: 'Oferta',
        contact: 'Kontakt'
      },
      intro: {
        image: 'Budynek',
        architect: 'Architekt',
        full_name: 'Aleksandra Żołnierzów'
      },
      links_labels: {
        home: 'Strona główna',
        company: 'Informacje o firmie',
        offer: 'Oferta',
        contact: 'Kontakt',
        facebook_button: 'Przejdź do facebooka',
      },
      links_titles: {
        facebook_button: 'Przejdź do facebooka',
      },
      footer: {
        contact: 'Kontakt',
        company: 'O Firmie',
        follow_us: 'Obserwuj Nas',
        nip: 'NIP: {{ nipNumber }}',
        address: 'Adres',
        phone: 'Telefon',
        email: 'Email',
        copyright: '2020 alex-architekt.pl',
        designer: 'Design by JM',
        about_us: 'Potrzeby i oczekiwania naszych Klientów są dla nas najważniejsze, dlatego doradzamy im w czasie całego procesu projektowego, aby&nbsp;podejmowane przez nich decyzje były jak najbardziej właściwe i&nbsp;optymalne.'
      },
      offers: {
        title: 'Oferta',
        description: 'Kompleksowa obsługa projektów wraz z&nbsp;wszystkimi formalnościami w&nbsp;urzędach.',
        contact: 'Do każdego klienta podchodzę indywidualnie. Razem ustalamy zakres i rodzaj współpracy. <br><br>Masz pytania? Zapraszam do kontaktu. ',
        house_design: {
          title: 'Projektowanie domów',
          option_1: 'domy jednorodzinne',
          option_2: 'rezydencje',
          option_3: 'domy szeregowe',
          option_4: 'domy wielorodzinne',
          option_5: 'domki letniskowe',
          option_6: 'adaptacje projektów gotowych',
          information: 'Projektuję domy, które pozwalają jak najlepiej wykorzystać przestrzeń. Moim celem jest, aby opracowywane przeze mnie projekty były jak najlepiej dopasowane do wymagań inwestorów.'
        },
        buildings: {
          title: 'Projektowanie budynków komercyjnych',
          option_1: 'obiekty usługowe',
          option_2: 'obiekty handlowe',
          option_3: 'obiekty przemysłowe', 
          option_4: 'budynki biurowe',
          information: 'Zapewniam dużą funkcjonalność rozwiązań, przy zachowaniu racjonalności i&nbsp;terminowości przygotowania projektów.'
        },
        modernization_and_reconstruction: {
          title: 'Modernizacje i przebudowy',
          subtitle: 'Kompleksowe opracowanie projektów:',
          option_1: 'rozbudowy',
          option_2: 'przebudowy',
          option_3: 'nadbudowy',
          option_4: 'modernizacje istniejących budynków',
          information: 'Na bazie istniejącej konstrukcji budynku tworzę nową jakość, idealnie dopasowując ją do&nbsp;istniejącej części. Dbam o&nbsp;jednolity charakter przebudowanego obiektu tak, aby był on od początku przemyślany w&nbsp;zakresie zastosowanych rozwiązań.'
        },
        inventories_and_expertise: {
          title: 'Inwentaryzacje i ekspertyzy',
          option_1: 'inwentaryzacje budowlane i&nbsp;architektoniczne',
          option_2: 'ekspertyzy i oceny stanu technicznego',
          option_3: 'legalizacje obiektów budowlanych',
          option_4: 'rozbiórki obiektów budowlanych',
          information: 'Inwentaryzacje budowlane przeprowadzam na&nbsp;podstawie własnych pomiarów.'
        },
        construction_supervision: {
          title: 'Nadzór budowlany',
          option_1: 'kierownik budowy',
          option_2: 'inspektor nadzoru inwestorskiego',
          information: 'W ramach nadzoru budowlanego przejmuję od&nbsp;inwestora rolę kierownika budowy oraz koordynuję przebieg prac.'
        },
        additional_options: {
          title: 'Dodatkowe usługi',
          option_1: 'uwzględnienie wykonania mapy do&nbsp;celów projektowych oraz badań geotechnicznych',
          option_2: 'przygotowanie kosztorysu inwestorskiego',
          option_3: 'uzyskanie warunków zabudowy',
          option_4: 'uzyskanie warunków przyłączeniowych do&nbsp;sieci',
          information: 'Pozyskuję wszelką dokumentację techniczną niezbędną do&nbsp;planowanego zamierzenia budowlanego.'
        }
      }
    }
  }
}
import React from 'react';

import { useTranslation } from 'react-i18next';
import { 
  Top,
  Box,
  Text,
  Offer,
  Title,
  Image,
  Option,
  Wrapper,
  StyledBuilding,
  Information,
  StyledCheckIcon,
} from './styled-components';

const Buildings = ({ className }) => {
  const { t } = useTranslation();

  return (
    <Offer className={className}>
      <Top>
        <Title dangerouslySetInnerHTML={{
            __html: t('offers.buildings.title')
          }}
        />
      </Top>
      <Box>
        <Image>
          <StyledBuilding />
        </Image>
        <Text>
          <Option>
            <StyledCheckIcon />
            <p dangerouslySetInnerHTML={{
                __html: t('offers.buildings.option_1')
              }}
            />
          </Option>              
          <Option>
            <StyledCheckIcon />
            <p dangerouslySetInnerHTML={{
                __html: t('offers.buildings.option_2')
              }}
            />
          </Option>
          <Option>
            <StyledCheckIcon />
            <p dangerouslySetInnerHTML={{
                __html: t('offers.buildings.option_3')
              }}
            />
          </Option>
          <Option>
            <StyledCheckIcon />
            <p dangerouslySetInnerHTML={{
                __html: t('offers.buildings.option_4')
              }}
            />
          </Option>
        </Text>
      </Box>
      <Wrapper>
        <Information dangerouslySetInnerHTML={{
            __html: t('offers.buildings.information')
          }}
        />
      </Wrapper>
    </Offer>
  )
}

export default Buildings;
import { css } from 'styled-components';

const breakpoints = {
  df: '0px',
  xxs: '360px',
  xs: '480px',
  s: '600px',
  pm: '720px',
  m: '800px',
  xm: '990px',
  l: '1086px',
  xl: '1280px',
  xxl: '1400px',
  xxxl: '1600px'
};

export const breakpoint = Object.keys(breakpoints).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (min-width: ${breakpoints[label]}) {
      ${css(...args)};
    }
  `;
  return acc;
}, {});

export default breakpoint;
import React from 'react';
import { Link } from 'gatsby';

import { isInnerHashLink } from '../utils/paths';

export default ({ to, ...props }) => {
  const isAbsolutePath = to.match('(http:|https:)') !== null;
  return isAbsolutePath || isInnerHashLink(to) ? (
    <a {...props} href={to}>
      {props.children}
    </a>
  ) : (
    <Link {...props} to={to} />
  );
};
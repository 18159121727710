import React from 'react';
import { useTranslation } from 'react-i18next';
import { 
  Top,
  Box,
  Text,
  Offer,
  Title,
  Image,
  Option,
  Wrapper,
  StyledHouse,
  Information,
  StyledCheckIcon,
} from './styled-components';


const Houses = ({ className }) => {
  const { t } = useTranslation();

  return (
    <Offer className={className}>
      <Top>
        <Title dangerouslySetInnerHTML={{
            __html: t('offers.house_design.title')
          }}
        />
      </Top>
      <Box>
        <Image>
          <StyledHouse />
        </Image>
        <Text>
          <Option>
            <StyledCheckIcon />
            <p dangerouslySetInnerHTML={{
              __html: t('offers.house_design.option_1')
              }}
            />
          </Option>              
          <Option>
            <StyledCheckIcon />
            <p dangerouslySetInnerHTML={{
              __html: t('offers.house_design.option_2')
              }}
            />
          </Option>
          <Option>
            <StyledCheckIcon />
            <p dangerouslySetInnerHTML={{
              __html: t('offers.house_design.option_3')
              }}
            />
          </Option>
          <Option>
            <StyledCheckIcon />
            <p dangerouslySetInnerHTML={{
              __html: t('offers.house_design.option_4')
              }}
            />
          </Option>
          <Option>
            <StyledCheckIcon />
            <p dangerouslySetInnerHTML={{
              __html: t('offers.house_design.option_5')
              }}
            />
          </Option>
          <Option>
            <StyledCheckIcon />
            <p dangerouslySetInnerHTML={{
              __html: t('offers.house_design.option_6')
              }}
            />
          </Option>
        </Text>
      </Box>
      <Wrapper>
        <Information dangerouslySetInnerHTML={{
          __html: t('offers.house_design.information')
          }}
        />
      </Wrapper>
    </Offer>
  )
}

export default Houses;
import Img from 'gatsby-image';
import styled from 'styled-components';
import { breakpoint } from '../../styles/breakpoints';

export const Image = styled(Img)`
  position: relative;
  
  width: 100%;
  height: 100vh;  
`;

export const TitleWrapper = styled.div`
  position: absolute;
  top: 5%;
  left: 50%;

  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  transform: translateX(-50%);
`;

export const Title = styled.h1`
  margin-bottom: 10px;

  color: ${({ theme }) => theme.colors.godGray};
  font-weight: 800;
  font-size: 2.3rem;
  font-family: ${({ theme }) => theme.fonts.raleway};
  letter-spacing: 1.5px;
  text-transform: uppercase;

  ${breakpoint.l`
    font-size: 2.5rem;
  `}
`
export const SubTitle = styled.p` 
  width: 255px;

  color: ${({ theme }) => theme.colors.godGray};
  font-weight: 400;
  font-size: 1.6rem;

  ${breakpoint.l`
    font-size: 2.1rem;
    width: 340px;
  `}
`;